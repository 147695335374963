import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo
} from "../../styledComponents/article"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import EventList from "../../components/EventList"
import RelatedQtArticles from "../../components/QuantumTouch/relatedQuantumTouchAricles"
import ShareTools from "../../components/socialLinks"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const WhoShouldLearnQT = ({ data, location }) => {
  
  const pageTitle = data.allWpInformation.nodes[0].title
  const pageContent = data.allWpInformation.nodes[0].content
  const urlRegex = data.allWpInformation.nodes[0].uri.replace("information/%information_category%/", "quantum-touch/");
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}`+ urlRegex
  return (
    <Layout displayNavBorder location={location}>
      <SEO 
        title={pageTitle} 
        description="The Quantum-Touch workshops are frequently attended by: Chiropractors, Osteopaths,Massage therapists Physiotherapists, Cranial sacral therapists, Reiki Masters, Shiatsu practitioners, Jin Shin Do practitioners, Acupuncturists, Reflexologists, Nurses, Doctors, Carers, Midwives, Doulas, Yoga teachers, Societys and organisations, Sports persons, Parents wanting to learn a great first aid tool, Vets, People experiencing pain or illness, Anyone who wishes to learn this healing technique"
        url="/quantum-touch/who-should-learn-quantum-touch"
      />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate pubdate dateTime={data.allWpInformation.nodes[0].dateGmt}><span className="publishedon">Published on</span> {data.allWpInformation.nodes[0].date}</ArticleDate>
          <ArticleAuthorInfo className="author"><span>Published by Karina Grant</span> </ArticleAuthorInfo>
          <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}
            <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
          </ArticleSection>
        )}
        <RelatedQtArticles data={data.relatedArticles} /> 
      </Article>
      
    </Layout>
  )
}

export default WhoShouldLearnQT

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "who-should-learn-quantum-touch"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date(formatString: "MMMM DD, YYYY")
        dateGmt
      }
    }
   
    relatedArticles : allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}}) {
      nodes {
        title
        slug
        uri
      }
    }
  }
`
